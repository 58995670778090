export interface LexmeaLink {
  href: string;
  title: string;
}

export function useLexmeaLinks() {
  const infoItems: LexmeaLink[] = [
    {
      href: "/intro",
      title: "Intro",
    },
    {
      href: "/vision",
      title: "Vision",
    },
    {
      href: "/team",
      title: "Team",
    },
    // {
    //   href: "/artikel",
    //   title: "Artikel",
    // },
    {
      href: "/faq",
      title: "F.A.Q.",
    },
  ];

  const miscItems: LexmeaLink[] = [
    {
      href: "/agb",
      title: "AGB",
    },
    {
      href: "/datenschutzhinweis",
      title: "Datenschutz",
    },
    {
      href: "/impressum",
      title: "Impressum",
    },
    {
      href: "/schemata",
      title: "Schemata",
    },
  ];

  return {
    infoItems,
    miscItems,
  };
}
